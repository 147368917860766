import Fade from "react-reveal/Fade";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <Fade>
            <div className="col-xs-12 col-md-6">
              {" "}
              <img id="lobby" src="img/about.webp" className="img-responsive" alt="" />{" "}
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h2>About Us</h2>
                <p>{props.data ? props.data.paragraph : "loading..."}</p>
                <h3>History</h3>
                <div className="list-style">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                    <span>두나무 컨소시엄 (라운드원 스튜디오 , 두나무 , 네이버)</span>
                  </ul>
                </div>
                <div id="logos" class="row">
                  <div class="col-xs-2"><img src="img/KBO.webp" className="img-responsive" alt=""/>{" "}</div>
                  <div class="col-xs-3"><img src="img/DOONAMOO.webp" className="img-responsive" alt=""/>{" "}</div>
                  <div class="col-xs-4"><img src="img/NAVER.webp" className="img-responsive" alt=""/>{" "}</div>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};
