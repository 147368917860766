import Fade from "react-reveal/Fade";

export const Features = (props) => {
  return (
    <div id='features' className='text-center'>
      <div className='container'>
        <Fade>
          <div className='col-md-10 col-md-offset-1 section-title'>
            <h2>WE ARE</h2>
          </div>
        </Fade>
          <div className='row'>
            {props.data
              ? props.data.map((d, i) => (
                <Fade>
                  {(i % 2) === 0 ? <div className="clearfix visible-xs-block" /> : ``}
                  <div key={`${d.title}-${i}`} className={
                    i === 4 ? 'col-xs-6 col-md-2 col-xs-offset-3 col-md-offset-0 features-desc'
                   : i === 0 ? 'col-xs-6 col-md-2 col-md-offset-1 features-desc'
                   : 'col-xs-6 col-md-2 features-desc' }>
                    {' '}
                    <img src={`img/we-${i + 1}.webp`} className="img-responsive" alt="" />
                    <h3>{d.title}</h3>
                    <p>{d.text}</p>
                  </div>
                </Fade>
                ))
              : 'Loading...'}
          </div>
      </div>
    </div>
  )
}
